// TODO: REFACTORING
import React from 'react';
import Style from './style.module.css';
import Circle from '@images/circle.svg';
import PinPosition from '@images/pinPosition.svg';
import { translate as t } from '../../utils/translate';
import { Link } from 'gatsby';

const LpNextFlyghtsCard = ({ aircraft, times, airports, price, link }) => {
  function handleClick(e) {
    e.preventDefault();
  }
  const TEXTS = {
    EXIT: t('departure'),
    DIRECT: t('direct'),
  };
  return (
    <div className={[Style.card, 'card'].join(' ')}>
      <Link to={link} className={Style.link}>
        <div className={Style.imageWrapper}>
          <img
            src={aircraft.url}
            alt={aircraft.name}
            title={aircraft.name}
            className={Style.image}
          />
        </div>

        <div className={Style.metaMiddle}>
          <div className={Style.flyghtNominations}>
            <p className={Style.leaveOn}>
              {TEXTS.EXIT + ':'} {times.leaveOn}
            </p>
          </div>

          <div className={Style.flyghtInformations}>
            <div className={Style.leave}>
              <p className={Style.time}>{times.departure}</p>

              <div className={Style.departureWrapper} onClick={handleClick}>
                <p className={Style.airportHint}>{airports.departureHint}</p>
                <span className={Style.showAirportButton}></span>
                <div className={Style.airportPopupDeparture}>
                  <p className={Style.airportPopupName}>{airports.departure}</p>
                </div>
              </div>
            </div>

            <div className={Style.path}>
              <p className={Style.pathDescription}>{TEXTS.DIRECT}</p>
              <span className={Style.pathIlustration}></span>
              <p className={Style.pathDuration}>{times.duration}</p>
            </div>

            <div className={Style.arrival}>
              <p className={Style.time}>{times.arrival}</p>
              <div className={Style.arrivalWrapper} onClick={handleClick}>
                <span className={Style.showAirportButton}></span>
                <p className={Style.airportHint}>{airports.arrivalHint}</p>
                <div className={Style.airportPopupArrival}>
                  <p className={Style.airportPopupName}>{airports.arrival}</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={Style.metaBotton}>
          <div className={Style.cities}>
            <p className={Style.location}>
              <img src={Circle} className={Style.locationIcon} />
              {[airports.departureCity, '-', airports.departureHint].join(' ')}
              <span className={Style.flyPath}></span>
            </p>
            <p className={Style.location}>
              <img src={PinPosition} className={Style.locationIcon} />
              {[airports.arrivalCity, '-', airports.arrivalHint].join(' ')}
            </p>
          </div>
          <p className={Style.price}>{price}</p>
        </div>

        <p className={Style.aircraftName}>{aircraft.name}</p>
      </Link>
    </div>
  );
};

export default LpNextFlyghtsCard;
