// TODO: REFACTORING
import React from 'react';
import * as Styled from './SharedFlightCard.style';
import Circle from '@images/circle.svg';
import PinPosition from '@images/pinPosition.svg';

import AnimatedTooltip from '@components/AnimatedComponents/AnimatedTooltip';

import { translate as t } from '@utils/translate';

const SharedFlightCard = ({ data }) => {
  const TEXTS = {
    LEAVE_ON: t('departure') + ': ',
    DIRECT: t('direct'),
  };

  const handleNavigate = () => {
    navigate(data.link);
  };

  return (
    <Styled.Card onClick={handleNavigate}>
      <Styled.CardHeader>
        <Styled.Image src={data.imageUrl} alt={data.name} />

        <Styled.HeaderDetails>
          <Styled.HeaderDetailsTitle>
            {TEXTS.LEAVE_ON}
            {data.leaveOn}
          </Styled.HeaderDetailsTitle>

          <Styled.HeaderDetailsInfo>
            <Styled.SpotTimeWrapper>
              <Styled.Time>{data.departureTime}</Styled.Time>
              <Styled.AcronymWrapper onClick={() => {}}>
                <AnimatedTooltip
                  label={data.departure}
                  aria-label={data.departure}
                >
                  <Styled.AcronymLabel>
                    {data.departureHint}
                  </Styled.AcronymLabel>
                </AnimatedTooltip>
              </Styled.AcronymWrapper>
            </Styled.SpotTimeWrapper>

            <Styled.TypeDurationWrapper>
              <Styled.PathDescription>{TEXTS.DIRECT}</Styled.PathDescription>
              <Styled.PathDuration>{data.duration}</Styled.PathDuration>
            </Styled.TypeDurationWrapper>

            <Styled.SpotTimeWrapper>
              <Styled.Time>{data.arrivalTime}</Styled.Time>

              <Styled.AcronymWrapper onClick={() => {}}>
                <AnimatedTooltip label={data.arrival} aria-label={data.arrival}>
                  <Styled.AcronymLabel>{data.arrivalHint}</Styled.AcronymLabel>
                </AnimatedTooltip>
              </Styled.AcronymWrapper>
            </Styled.SpotTimeWrapper>
          </Styled.HeaderDetailsInfo>
        </Styled.HeaderDetails>
      </Styled.CardHeader>

      <Styled.CardFooter>
        <Styled.FooterName>
          <Styled.Name>{data.name}</Styled.Name>
        </Styled.FooterName>

        <Styled.FooterInfo>
          <Styled.Cities>
            <Styled.Location>
              <Styled.LocationIcon src={Circle} />
              {[data.departureCity, '-', data.departureHint].join(' ')}
              <Styled.FlyPath></Styled.FlyPath>
            </Styled.Location>

            <Styled.Location>
              <Styled.LocationIcon src={PinPosition} />
              {[data.arrivalCity, '-', data.arrivalHint].join(' ')}
            </Styled.Location>
          </Styled.Cities>
          <Styled.Price>{data.price}</Styled.Price>
        </Styled.FooterInfo>
      </Styled.CardFooter>
    </Styled.Card>
  );
};

export default SharedFlightCard;
