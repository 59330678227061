// TODO: REFACTORING
import React from 'react';
import Style from './style.module.css';
import LpForm from '../LpForm';
import '../slick.css';
import Img from '@components/ImageWrapper';
import solveImageLink from '@utils/solveImageLink';

const LpHero = ({
  flyCall,
  cityName,
  cityQuote,
  cityImageAlt,
  lpForm,
  imageDesktop,
}) => {
  let lpBackground = solveImageLink(imageDesktop);
  return (
    <section className={Style.heroSections}>
      <Img
        src={imageDesktop}
        style={{ position: 'absolute', overflow: 'inherit' }}
        className={Style.cityImage}
        imgStyle={{ objectPosition: 'center top' }}
        alt={cityImageAlt}
      />
      <div className={Style.overlay} />
      <div className={[Style.heroWrapper, 'container'].join(' ')}>
        <p className={Style.flyTo}>{flyCall}</p>
        <h2 className={Style.cityName}>{cityName}</h2>
        <p className={Style.cityQuote}>{cityQuote}</p>
        <LpForm
          {...lpForm}
          backgroundImage={lpBackground && lpBackground.src}
        />
      </div>
    </section>
  );
};

export default LpHero;
