import React from 'react';
import { useSelector } from 'react-redux';
import Layout from '../components/layout';
import SEO from '../components/seo';
import LpHero from '../components/LpHero';
import LpFlightSection from '../components/LpFlightSection';
import LpQuotationSection from '../components/LpQuotationSection';
import useFlapperApi from '../utils/useFlapperApi';
import { graphql } from 'gatsby';
import { apiHost } from '../utils/constants';
import { getHotSitesSections } from '../utils/functions';
import {
  mapSharedFlightsItem,
  mapCustomOffersItem,
  getFeedUrl,
} from '@utils/feedHelper';
import SectionContainer from '@components/ContainerComponents/SectionContainer';
import SliderContainer from '@components/ContainerComponents/SliderContainer';
import CustomCharterOfferCard from '@components/Cards/CustomCharterOfferCard';
import SharedFlightCard from '@components/Cards/SharedFlightCard';

const LP = ({ data }) => {
  const currency = useSelector(state => state.general.currency);
  const {
    wordpressWpHotsites: {
      title,
      yoast_head,
      polylang_current_lang,
      polylang_translations,
      acf: {
        hotsite_subtitle: subtitle,
        hotsite_pretitle: pretitle,
        hotsite_image: image,
        hotsite_api_feed_link: feedLink,
        hotsite_city_id: cityID,
        hotsite_name_getlocations: getLocationsName,
        hotsite_parameters_feed_get: params,
      },
    },
  } = data;
  const originDestinationParams = {
    originCityId: null,
    originSpotId: null,
    originName: null,
    destinationCityId: null,
    destinationSpotId: null,
    destinationName: null,
    currency: currency.code,
  };

  params.forEach(param => {
    originDestinationParams[param['name']] = param['value'];
  });

  const url = getFeedUrl(feedLink, params);
  const [{ dataResponse, isLoading, isError }] = useFlapperApi(
    `${apiHost}${url}`,
    {},
    { headers: { 'Accept-Language': polylang_current_lang } }
  );
  const { sharedFlights, customOffers } = getHotSitesSections(dataResponse);

  return (
    <Layout
      footerStyles={{ marginBottom: '104px' }}
      pageLang={polylang_current_lang}
      translations={polylang_translations}
      useTranslationsPath={true}
    >
      <SEO yoast_head={yoast_head} pageLang={polylang_current_lang} />
      <LpHero
        flyCall={pretitle}
        cityName={title}
        cityQuote={subtitle}
        imageDesktop={image}
        cityImageAlt={title}
        lpForm={{
          initialOriginId:
            originDestinationParams['originCityId'] ||
            originDestinationParams['originSpotId'],
          initialDestinationId:
            originDestinationParams['destinationCityId'] ||
            originDestinationParams['destinationSpotId'],
          initialOriginSuggestion: originDestinationParams['originName'],
          initialDestinationSuggestion:
            originDestinationParams['destinationName'],
        }}
      />
      {isLoading ? (
        <p>Loading ...</p>
      ) : isError ? (
        <></>
      ) : (
        <>
          {sharedFlights &&
            sharedFlights.length > 0 &&
            sharedFlights.map((flight, idx) => {
              const items = flight.items.map(item => {
                return {
                  data: mapSharedFlightsItem(item),
                };
              });

              return (
                <>
                  <SectionContainer
                    title={flight.title}
                    subtitle={flight.subtitle}
                  >
                    <SliderContainer
                      Card={SharedFlightCard}
                      items={[...items]}
                      settingsName={'medium'}
                      stylesName={'normal'}
                      hasGrid={true}
                    />
                  </SectionContainer>

                  <LpFlightSection
                    key={idx}
                    title={flight.title}
                    subtitle={flight.subtitle}
                    cards={[...flight.items]}
                    offset={idx === 0}
                    language={polylang_current_lang}
                  />
                </>
              );
            })}
          {customOffers &&
            customOffers.length > 0 &&
            customOffers.map((flight, idx) => {
              const items = flight.items.map(item => {
                return {
                  data: mapCustomOffersItem(item, polylang_current_lang),
                };
              });

              return (
                <SectionContainer
                  title={flight.title}
                  subtitle={flight.subtitle}
                  key={idx}
                >
                  <SliderContainer
                    Card={CustomCharterOfferCard}
                    items={items}
                  />
                </SectionContainer>
              );
            })}
        </>
      )}
    </Layout>
  );
};

export const query = graphql`
  query($wordpress_id: Int) {
    wordpressWpHotsites(wordpress_id: { eq: $wordpress_id }) {
      title
      yoast_head
      polylang_current_lang
      polylang_translations {
        path
        polylang_current_lang
      }
      acf {
        hotsite_api_feed_link
        hotsite_city_id
        hotsite_subtitle
        hotsite_pretitle
        hotsite_name_getlocations
        hotsite_parameters_feed_get {
          name
          value
        }
        hotsite_image {
          localFile {
            childImageSharp {
              fluid(maxWidth: 1920, quality: 80) {
                ...GatsbyImageSharpFluid
                src
              }
            }
          }
        }
      }
    }
  }
`;

export default LP;
