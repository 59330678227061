import { useState, useEffect } from 'react';

const useFlapperApi = (initialUrl, initialData, initialOptions) => {
  const [dataResponse, setData] = useState(initialData);
  const [url, setUrl] = useState(initialUrl);
  const [options] = useState(initialOptions);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isEnd, setIsEnd] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setIsError(false);
      setIsLoading(true);
      try {
        const response = await fetch(url, options);
        const json = await response.json();
        if (response.status === 200) {
          setData(json);
        } else {
          setIsError(true);
        }
      } catch (error) {
        setIsError(true);
      }
      setIsLoading(false);
      setIsEnd(true);
    };
    fetchData();
  }, [url]);

  return [{ dataResponse, isLoading, isError, isEnd }, setUrl];
};

export default useFlapperApi;
