// TODO: TRANSLATION PATTERN
// TODO: REFACTORING
import React from 'react';
import { useState } from 'react';
import Style from './style.module.css';
import './style.css';
import Slider from 'react-slick';
import LpNextFlightsCard from '../LpNextFlightsCard';
import Next from './next.svg';
import { dateToCardFormat, dateToHour, minutesToHours } from '@utils/dates';
import { formatCurrency } from '@utils/currency';
import { translate, parseLangType } from '@utils/translate';
import aircraftNameHelper from '@utils/aircraftNameHelper';
import { platformFlightRoute } from '@utils/constants';

const getFligthAcronym = (originSpot, destinationSpot) => {
  let departureHint;
  let arrivalHint;

  const originAcr = originSpot.helipad
    ? originSpot.helipad.acronym
    : originSpot.airport.acronym;
  const destinationAcr = destinationSpot.helipad
    ? destinationSpot.helipad.acronym
    : destinationSpot.airport.acronym;
  const originOaci = originSpot.helipad
    ? originSpot.helipad.oaci
    : originSpot.airport.oaci;
  const destinationOaci = destinationSpot.helipad
    ? destinationSpot.helipad.oaci
    : destinationSpot.airport.oaci;

  if (originAcr && destinationAcr) {
    departureHint = originAcr;
    arrivalHint = destinationAcr;
  } else {
    departureHint = originOaci;
    arrivalHint = destinationOaci;
  }

  return { departureHint, arrivalHint };
};

const prepareSharedCard = (flight, language) => {
  const { departureHint, arrivalHint } = getFligthAcronym(
    flight.originSpot,
    flight.destinationSpot
  );
  const flightUrl = `${platformFlightRoute}/${flight.id}/${flight.aircraftId}`;

  return {
    aircraft: {
      name: aircraftNameHelper(flight.aircraft),
      url: flight.aircraft.highlightImageUrl
        ? flight.aircraft.highlightImageUrl
        : flight.aircraft.model.highlightImageUrl,
    },
    times: {
      leaveOn: dateToCardFormat(flight.flightDatetime, language),
      departure: dateToHour(flight.departureDatetime),
      arrival: dateToHour(flight.arrivalDatetime),
      duration: minutesToHours(flight.estimatedTime),
    },
    airports: {
      departure: flight.originSpot.name,
      departureHint: departureHint,
      departureCity: flight.originSpot.address.city.name,

      arrival: flight.destinationSpot.name,
      arrivalHint: arrivalHint,
      arrivalCity: flight.destinationSpot.address.city.name,
    },
    price: formatCurrency({ value: flight.startingPrice }),
    link: flightUrl,
  };
};

const SlideComponent = ({ cards, cardsAmount }) => {
  const sliderSettings = {
    slidesToShow: 2,
    infinite: false,
    prevArrow: <PrevButton />,
    nextArrow: <NextButton />,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const shownCards = cards.slice(0, cardsAmount);

  return (
    <>
      <Slider {...sliderSettings} className="slider">
        {shownCards.map((card, index) => (
          <LpNextFlightsCard {...card} key={index} />
        ))}
      </Slider>
      <div className={Style.whiteFog}></div>
    </>
  );
};

const PrevButton = ({ className, onClick }) => {
  return (
    <button
      className={[Style.previousArrow, className].join(' ')}
      onClick={onClick}
    >
      <img
        src={Next}
        className={Style.previousIcon}
        alt={translate('Anterior')}
      />
      <span>{translate('Anterior')}</span>
    </button>
  );
};

const NextButton = ({ className, onClick }) => {
  return (
    <button
      className={[Style.nextArrow, className].join(' ')}
      onClick={onClick}
    >
      <span>{translate('Próximo')}</span>{' '}
      <img src={Next} className={Style.nextIcon} alt={translate('Próximo')} />
    </button>
  );
};

const MoreButton = ({
  totalCards,
  gridPage,
  HandleGrid,
  initialShownCards,
}) => {
  const availableCards = totalCards - initialShownCards - (gridPage - 1) * 12;
  if (availableCards < 1) return '';
  return (
    <button className={Style.moreButton} onClick={HandleGrid}>
      {translate('Mais')} {availableCards > 11 ? '12' : availableCards}{' '}
      {availableCards > 1 ? translate('voos') : translate('voo')}
    </button>
  );
};

const GridComponent = ({ cards, gridCards }) => {
  return (
    <div className={Style.grid} className="grid container">
      {cards.map((card, index) => {
        if (index < gridCards) {
          return <LpNextFlightsCard {...card} key={index} />;
        }
      })}
    </div>
  );
};

const LpFlightSection = ({ title, subtitle, cards, offset, language }) => {
  const locateLanguage = parseLangType(language);
  const cardsList = cards.map(item => prepareSharedCard(item, locateLanguage));
  const initialShownCards = 6;

  const [totalCards] = useState(cards.length);
  const [gridPage, setGridPage] = useState(1);
  const [mainComponent, setMainComponent] = useState(
    <SlideComponent cardsAmount={initialShownCards} cards={cardsList} />
  );

  const HandleGrid = () => {
    const currentScrollY = window.scrollY;
    let showCards = initialShownCards + 12 * gridPage;
    setGridPage(gridPage + 1);
    setMainComponent(<GridComponent cards={cardsList} gridCards={showCards} />);
    window.scrollTo(0, currentScrollY + 1);
  };

  return (
    <section className={Style.flightSection}>
      <div
        className={[Style.sectionHeader, offset ? Style.offset : ''].join(' ')}
      >
        <div className={[Style.headerWrapper, 'container'].join(' ')}>
          <h2 className={Style.title}>{title}</h2>
          <p className={Style.subtitle}>{subtitle}</p>
        </div>
      </div>

      <div className={Style.cardsWrapper} id="cardsWrapper">
        {mainComponent}
        <MoreButton
          initialShownCards={initialShownCards}
          totalCards={totalCards}
          HandleGrid={HandleGrid}
          gridPage={gridPage}
        />
      </div>
    </section>
  );
};

export default LpFlightSection;
