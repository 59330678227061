import styled from 'styled-components';

export const Card = styled.div`
  width: calc((94vw - var(--container-size)) / 2);
  max-width: 600px;
  box-sizing: border-box;
  margin-right: 20px;

  height: auto;
  border-radius: 35px;
  padding: 10px;
  background: transparent
    linear-gradient(120deg, rgba(255, 255, 255, 0) 30%, #00000017 100%) 0% 0%
    no-repeat padding-box;

  @media (max-width: 1024px) {
    width: calc((100vw - var(--container-size)));
  }
`;

export const CardHeader = styled.div`
  display: flex;
  justify-content: center;
  border-radius: 35px;
  background-color: white;
`;

export const Image = styled.img`
  height: 130px;
  width: 42%;
  border-radius: 35px 0px 0px 35px;
`;

export const HeaderDetails = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  padding: 20px;
  border-radius: 0 35px 35px 0;
`;

export const HeaderDetailsTitle = styled.p`
  width: 100%;
  margin-right: 0;
  margin-bottom: 20px;
  text-align: center;
  font-size: 12px;
  color: #39383d;
  font-weight: 700;
`;

export const HeaderDetailsInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const SpotTimeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 70px;
  height: 70px;
`;

export const Time = styled.p`
  font-size: 25px;
  font-weight: 500;
  color: #39383d;
  margin-bottom: 0px;
`;

export const TypeDurationWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PathDescription = styled.p`
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 500;
  margin-right: 5px;
  margin-bottom: 8px;
  color: #000000;

  &:after {
    content: '';
    display: inline-block;
    height: 2px;
    width: 25px;
    margin-left: 5px;
    background-color: #00bfa5;
  }
`;

export const AcronymWrapper = styled.div``;

export const AcronymLabel = styled.span`
  color: #91939b;
  font-size: 13px;
  font-weight: 500;
  margin: 0;

  &:after {
    content: '';
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid #91939b;
    display: inline-block;
    margin: 0 10px 2px;
  }
`;

export const PathDuration = styled.p`
  text-align: center;
  margin-bottom: 0;
  font-size: 13px;
  font-weight: 600;
  color: #000000;
`;

export const CardFooter = styled.div`
  display: flex;
  justify-content: center;
  height: 60px;
`;

export const FooterName = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 42%;
`;

export const Name = styled.span`
  font-size: 12px;
  color: #91939b;
  font-weight: 600;
`;

export const FooterInfo = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex: 1;
`;

export const Cities = styled.div`
  margin-right: 0;
`;

export const Location = styled.p`
  position: relative;
  font-size: 12px;
  color: #707070;
  font-weight: 500;
  margin-bottom: 7px;
  display: flex;

  &:last-of-type {
    margin-bottom: 0;
  }
`;

export const LocationIcon = styled.img`
  margin-right: 10px;
  margin-bottom: 0px;
`;

export const FlyPath = styled.span`
  position: absolute;
  top: 14px;
  left: 3px;
  display: block;
  height: 8px;
  width: 2px;
  background-color: #39383d;
`;

export const Price = styled.p`
  align-self: center;
  justify-self: flex-end;
  margin-bottom: 0;
  margin-left: auto;

  font-size: 23px;
  font-weight: 500;
  color: #39383d;
  margin-bottom: 0;
`;
